// @flow
import {Typography, withStyles, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    fontSize: '18px',
    lineHeight: '32px',
    fontWeight: 700,
    color: theme.palette.common.black,
  },
});

const SectionCardTitle = withStyles(styles)(Typography);

export default SectionCardTitle;
