// @flow
import React, {PureComponent, type Node} from 'react';
import classnames from 'classnames';

import {withStyles, type Theme, type Classes} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create('box-shadow', {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.ease,
    }),
    '&$active': {
      boxShadow: theme.shadows[12],
    },
  },
  active: {},
  stripe: {
    height: 5,
  },
  content: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
  },
});

type Props = {
  active?: boolean,
  className?: string,
  stripeColor: string,
  children: Node,
  // injected
  classes: Classes<typeof styles>,
};

class SectionCard extends PureComponent<Props> {
  render() {
    const {classes, active, className, stripeColor, children} = this.props;

    return (
      <div
        className={classnames(classes.root, className, {
          [classes.active]: active,
        })}>
        <div
          className={classes.stripe}
          style={{backgroundColor: stripeColor}}
        />
        <div className={classes.content}>{children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(SectionCard);
