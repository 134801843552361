// @flow
import React, {type ElementConfig} from 'react';
import classnames from 'classnames';

import {withStyles, type Classes, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
      alignItems: 'center',
      textAlign: 'center',
    },
  },
});

type Props = {
  ...ElementConfig<'div'>,
  // injected
  classes: Classes<typeof styles>,
};

// use this for groups of vertical text content that need to be
// centered and under 500px on mobile/tablet
function SectionTextGroup({classes, className, ...props}: Props) {
  //$ExpectError - this doesn't work - ElementConfig is inexact, so we can't make Props exact
  return <div className={classnames(classes.root, className)} {...props} />;
}

export default withStyles(styles)(SectionTextGroup);
