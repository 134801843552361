// @flow
import React from 'react';
import classnames from 'classnames';

import {Typography, withStyles, type Classes, type Theme} from 'ui';
import {FullWidthContent} from 'layout';
import {colors} from 'theme/v2';
import {Section} from 'site/Section';

const styles = (theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  background: {
    backgroundColor: colors.grey8,
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(50),
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
    },
  },
  contentRoot: {
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.only('md')]: {
      minHeight: theme.spacing(50),
    },
    [theme.breakpoints.only('lg')]: {
      minHeight: theme.spacing(80),
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: theme.spacing(110),
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7.5),
      paddingBottom: theme.spacing(7.5),
      alignItems: 'center',
      textAlign: 'center',
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  headline: {
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '34px',
      lineHeight: '42px',
      maxWidth: theme.spacing(50),
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '40px',
      lineHeight: '48px',
      maxWidth: theme.spacing(50),
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '40px',
      lineHeight: '48px',
      maxWidth: theme.spacing(60),
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '48px',
      lineHeight: '56px',
      maxWidth: theme.spacing(70),
    },
  },
  whiteText: {
    [theme.breakpoints.up('md')]: {
      color: theme.palette.common.white,
    },
  },
});

type Props = {
  backgroundClassName: string,
  headline: string,
  whiteText?: boolean,
  // injected
  classes: Classes<typeof styles>,
};

// you must provide your own backgroundClassName with the following properties set:
//   - backgroundImage
//   - backgroundSize
//   - backgroundRepeat
//   - backgroundAttachment
//   - backgroundPositionX
//   - backgroundPositionY

function InternalHero({
                        backgroundClassName,
                        headline,
                        whiteText,
                        classes,
                      }: Props) {
  return (
      <Section className={classes.root}>
        <div className={classnames(classes.background, backgroundClassName)} />
        <FullWidthContent
            className={classes.contentRoot}
            contentClassName={classnames({[classes.content]: headline})}>
          {headline && (
              <Typography
                  className={classnames(classes.headline, {
                    [classes.whiteText]: whiteText,
                  })}
                  component="h1">
                {headline}
              </Typography>
          )}
        </FullWidthContent>
      </Section>
  );
}

export default withStyles(styles)(InternalHero);