// @flow

import {Typography, withStyles, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.common.black,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
});

const SectionOverline = withStyles(styles)(Typography);

export default SectionOverline;
