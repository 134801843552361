// @flow
import {colors} from 'theme/v2';
import {Typography, withStyles, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    fontSize: '32px',
    fontWeight: 700,
    color: colors.grey1,
    lineHeight: '1.2em',
  },
});

const SectionHeadline = withStyles(styles)(Typography);

export default SectionHeadline;
