// @flow
import React, {type Node} from 'react';
import classnames from 'classnames';

import {withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';
import {FullWidthContent} from 'layout';

import {Section} from 'site/Section';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: colors.bossanova1,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
});

type Props = {
  children: Node,
  className?: string,
  // injected
  classes: Classes<typeof styles>,
};

function PurpleBar({children, classes, className}: Props) {
  return (
    <Section className={classnames(classes.root, className)}>
      <FullWidthContent contentClassName={classes.content}>
        {children}
      </FullWidthContent>
    </Section>
  );
}

export default withStyles(styles)(PurpleBar);
