// @flow
import React, {PureComponent} from 'react';

import {withStyles, type Classes, type Theme} from 'ui';
import {FullWidthContent} from 'layout';
import {Section, SectionHeadline} from 'site/Section';
import {CheckAddressBox} from 'site/CheckAddress';

const styles = (theme: Theme) => ({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  headline: {
    marginBottom: theme.spacing(5),
    fontSize: '24px',
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class CallToAction extends PureComponent<Props> {
  render() {
    const {classes} = this.props;

    return (
      <Section className={classes.root}>
        <FullWidthContent contentClassName={classes.content}>
          <SectionHeadline className={classes.headline} align="center">
            Find out if Common is available where you live:
          </SectionHeadline>
          <CheckAddressBox text="Check your address" />
        </FullWidthContent>
      </Section>
    );
  }
}

export default withStyles(styles)(CallToAction);
