// @flow
import React from 'react';
import Link from 'next/link';

import {DesktopLinks, DesktopLink} from 'header';
import {type ActiveRoute} from './types';

type Props = {
    activeRoute?: ActiveRoute,
};

function SiteDesktopLinks({activeRoute}: Props) {
    return (
        <DesktopLinks>
            <Link href="/about" passHref>
                <DesktopLink active={activeRoute === '/about'}>About</DesktopLink>
            </Link>
            <Link href="/technology" passHref>
                <DesktopLink active={activeRoute === '/technology'}>
                    Technology
                </DesktopLink>
            </Link>
            <Link href="/press" passHref>
                <DesktopLink active={activeRoute === '/press'}>Press</DesktopLink>
            </Link>
        </DesktopLinks>
    );
}

export default SiteDesktopLinks;