// @flow
import React, {type ElementConfig, PureComponent} from 'react';
import classnames from 'classnames';

import {colors} from 'theme/v2';
import {Typography, withStyles, type Theme, type Classes} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    display: 'inline-block',
    color: [colors.persian1, '!important'],
    textDecoration: 'none',
    fontWeight: 700,
    borderBottom: `2px solid ${colors.persian1}`,
  },
});

type Props = {
  ...ElementConfig<typeof Typography>,
  // injected
  classes: Classes<typeof styles>,
};

class SectionUnderlinedLink extends PureComponent<Props> {
  render() {
    const {classes, className, children, ...props} = this.props;

    return (
      <Typography
        className={classnames(classes.root, className)}
        variant="body2"
        component="a"
        {...props}>
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(SectionUnderlinedLink);
