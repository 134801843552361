// @flow
import React, {type ElementConfig} from 'react';

type Props = ElementConfig<'section'>;

// noop for now, placeholder for styling all sections in the future
function Section(props: Props) {
  return <section {...props} />;
}

export default Section;
