// @flow
import React, {PureComponent, type Node} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';

import {withStyles, type Classes, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '&:not($expanded)': {
      [theme.breakpoints.down('md')]: {
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(255, 255, 255, 0.5)',
      borderBottomStyle: 'solid',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(8),
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  },
  headerText: {
    [theme.breakpoints.down('md')]: {
      // marginRight: theme.spacing.unit * 0.5,
    },
  },
  expandIcon: {
    marginTop: theme.spacing(1) * -0.5,
    color: theme.palette.common.white,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.ease,
    }),
    '&$expanded': {
      transform: 'rotate(-180deg)',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  links: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: theme.transitions.create('max-height', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.ease,
    }),
    '&:not($expanded)': {
      [theme.breakpoints.down('md')]: {
        maxHeight: 0,
      },
    },
    '&$expanded': {
      [theme.breakpoints.down('md')]: {
        // a number close to (but not under) the tallest link group height
        maxHeight: 260,
      },
    },
  },
  expanded: {},
});

type Props = {
  header: Node,
  children: Node,
  // injected
  classes: Classes<typeof styles>,
};

type State = {
  expanded: boolean,
};

class LinkGroup extends PureComponent<Props, State> {
  state = {
    expanded: false,
  };

  handleRootClick = () => {
    if (!this.state.expanded) {
      this.setState({expanded: true});
    }
  };

  handlHeaderClick = () => {
    if (this.state.expanded) {
      this.setState({expanded: false});
    }
  };

  render() {
    const {header, children, classes} = this.props;
    const {expanded} = this.state;

    return (
      <div
        className={classnames(classes.root, {
          [classes.expanded]: expanded,
        })}
        onClick={this.handleRootClick}>
        <div className={classes.header} onClick={this.handlHeaderClick}>
          <div className={classes.headerText}>{header}</div>
          <ExpandMoreIcon
            className={classnames(classes.expandIcon, {
              [classes.expanded]: expanded,
            })}
          />
        </div>
        <div
          className={classnames(classes.links, {
            [classes.expanded]: expanded,
          })}>
          {children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LinkGroup);
