// @flow
import React, {PureComponent} from 'react';
import Link from 'next/link';

import {Typography, withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';
import {FullWidthContent} from 'layout';
import {Section} from 'site/Section';
import LinkGroup from './LinkGroup';
import Icon from './Icon.svg';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: colors.bossanova1,
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileHorizontalDivider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.common.white,
    opacity: 0.5,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  links: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  linksLeft: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  linksRight: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  socialIcons: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 'auto',
      justifyContent: 'flex-end',
    },
  },
  socialIconLink: {
    height: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('lg')]: {
      '&:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  socialIcon: {
    height: theme.spacing(4),
  },
  copyright: {
    fontSize: '14px',
    color: theme.palette.common.white,
    opacity: 0.5,
  },
  desktopIconContainer: {
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  linksHeader: {
    fontWeight: 700,
    fontSize: '14px',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
  },
  link: {
    fontWeight: 300,
    marginTop: theme.spacing(2),
    color: [theme.palette.common.white, '!important'],
    textDecoration: ['none', '!important'],
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px',
    },
  },
  mobileIconContainer: {
    paddingTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  icon: {
    width: theme.spacing(7),
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class Footer extends PureComponent<Props> {
  render() {
    const {classes} = this.props;

    return (
        <Section className={classes.root}>
          <FullWidthContent contentClassName={classes.content}>
            <div className={classes.links}>
              <div className={classes.linksLeft}>
                <div className={classes.desktopIconContainer}>
                  <Icon className={classes.icon} />
                </div>
                <LinkGroup
                    header={
                      <Typography className={classes.linksHeader}>
                        Company
                      </Typography>
                    }>
                  <Link href="/about" passHref>
                    <Typography className={classes.link} component="a">
                      About us
                    </Typography>
                  </Link>
                  <Link href="/press" passHref>
                    <Typography className={classes.link} component="a">
                      News & press
                    </Typography>
                  </Link>
                  <Link href="/technology" passHref>
                    <Typography className={classes.link} component="a">
                      Technology
                    </Typography>
                  </Link>
                </LinkGroup>
                <LinkGroup
                    header={
                      <Typography className={classes.linksHeader}>
                        Resources
                      </Typography>
                    }>
                  <Link href="/privacy" passHref>
                    <Typography className={classes.link} component="a">
                      Privacy policy
                    </Typography>
                  </Link>
                  <Link href="/privacy#open-internet-statement" passHref>
                    <Typography className={classes.link} component="a">
                      Net neutrality
                    </Typography>
                  </Link>
                </LinkGroup>
              </div>
              <div className={classes.linksRight}>
                <Typography className={classes.copyright}>
                  2020 Common Networks, Inc.
                </Typography>
              </div>
            </div>
          </FullWidthContent>
          <div className={classes.mobileHorizontalDivider} />
          <div className={classes.mobileIconContainer}>
            <Icon className={classes.icon} />
          </div>
        </Section>
    );
  }
}

export default withStyles(styles)(Footer);