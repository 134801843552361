// @flow
import {Typography, withStyles, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    fontSize: '18px',
    lineHeight: '32px',
    fontWeight: 400,
    [theme.breakpoints.only('xs')]: {
      lineHeight: '30px',
    },
  },
});

const SectionBody1 = withStyles(styles)(Typography);

export default SectionBody1;
