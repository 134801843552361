// @flow
import React from 'react';
import Link from 'next/link';

import {MobileMenu, MobileMenuLink} from 'header';
import {type ActiveRoute} from './types';

type Props = {
    activeRoute?: ActiveRoute,
};

function SiteMobileMenu({activeRoute}: Props) {
    return (
        <MobileMenu>
            <Link href="/about" passHref>
                <MobileMenuLink active={activeRoute === '/about'}>About</MobileMenuLink>
            </Link>
            <Link href="/technology" passHref>
                <MobileMenuLink active={activeRoute === '/technology'}>
                    Technology
                </MobileMenuLink>
            </Link>
            <Link href="/press" passHref>
                <MobileMenuLink active={activeRoute === '/press'}>Press</MobileMenuLink>
            </Link>
        </MobileMenu>
    );
}

export default SiteMobileMenu;