// @flow
import React, {PureComponent, type Node, type ElementConfig} from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';

import {Button, withStyles, type Theme, type Classes} from 'ui';
import {colors} from 'theme/v2';

const styles = (theme: Theme) => ({
  button: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    color: colors.persian1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: 700,
  },
  arrow: {
    color: colors.persian1,
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
});

type Props = {
  ...ElementConfig<typeof Button>,
  className?: string,
  arrowClassName?: string,
  labelClassName?: string,
  children: Node,
  // injected
  classes: Classes<typeof styles>,
};

class SectionArrowButton extends PureComponent<Props> {
  render() {
    const {
      classes,
      children,
      className,
      arrowClassName,
      labelClassName,
      ...props
    } = this.props;

    return (
      <Button
        disableRipple
        className={classnames(classes.button, className)}
        classes={{label: classnames(classes.label, labelClassName)}}
        {...props}>
        {children}
        <ArrowForwardIcon
          className={classnames(classes.arrow, arrowClassName)}
        />
      </Button>
    );
  }
}

export default withStyles(styles)(SectionArrowButton);
