// @flow
import React, {Fragment} from 'react';
import Link from 'next/link';

import {withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';
import {Header, HeaderLogo} from 'header';
import DesktopLinks from './DesktopLinks';
import MobileMenu from './MobileMenu';
import {type ActiveRoute} from './types';

const styles = (theme: Theme) => ({
    signupButton: {
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(5),
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(20),
            height: theme.spacing(6),
        },
        background: [colors.persian1, '!important'],
    },
    signupButtonLabel: {
        fontWeight: 700,
    },
});

type Props = {
    activeRoute?: ActiveRoute,
    // injected
    classes: Classes<typeof styles>,
};

function SiteHeader({activeRoute, classes}: Props) {
    return (
        <Fragment>
            <Header
                left={
                    <Link href="/" passHref>
                        <HeaderLogo />
                    </Link>
                }
                right={<DesktopLinks activeRoute={activeRoute} />}
                mobileMenu={<MobileMenu activeRoute={activeRoute} />}
            />
        </Fragment>
    );
}

export default withStyles(styles)(SiteHeader);